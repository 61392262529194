import React, { Component } from "react";


import ScrollContainer from 'react-indiana-drag-scroll'


import teamBg from '../../assets/img/team-bg.webp';
import teamVector from '../../assets/img/team-vector.webp';


//images for the whole team
import Saoud from "../../assets/img/team/Saoud.jpg"
import Anis from "../../assets/img/team/Anis.jpg"
import Barry from "../../assets/img/team/Barry.jpg"
import DavidSosella from "../../assets/img/team/David-Sosella.jpg"
import ElisaGuareschi from "../../assets/img/team/Elisa-Guareschi.jpg"
import Grim from "../../assets/img/team/Grim.jpg"
import IvkoT from "../../assets/img/team/IvkoT.jpg"
import James from "../../assets/img/team/James.jpg"
import JessicaPuleo from "../../assets/img/team/Jessica-Puleo.jpg"
import Karode from "../../assets/img/team/Karode.jpg"
import Macca from "../../assets/img/team/Macca.jpg"
import MarcoGiannone from "../../assets/img/team/Marco-Giannone.jpg"
import MarcoLio from "../../assets/img/team/Marco-Lio.jpg"
import MarcoMendola from "../../assets/img/team/Marco-Mendola.jpg"
import Martini from "../../assets/img/team/Martini.jpg"
import PaoloLuca from "../../assets/img/team/Paolo-Luca.jpg"
import Paulo from "../../assets/img/team/Paulo.jpg"
import Rodolfo from "../../assets/img/team/Rodolfo.jpg"
import SappySteve from "../../assets/img/team/Sappy.jpg"
import SaraPenco from "../../assets/img/team/Sara-Penco.jpg"

//team json
const team = [
    {
        name: "Lio",
        role: "Creative Dir.",
        team: "design",
        image: MarcoLio,
        link: "https://www.behance.net/marcocantalamessa/"
    },
    {
        name: "Jimmy",
        role: "Creative Dir.",
        team: "design",
        image: MarcoGiannone,
        link: "https://www.behance.net/markgianno"
    },
    {
        name: "David",
        role: "Lead Illustrator",
        team: "design",
        image: DavidSosella,
        link: "https://www.behance.net/david_sossella"
    },
    {
        name: "Sara",
        role: "Illustrator",
        team: "design",
        image: SaraPenco,
        link: "https://www.behance.net/sara_penco"
    },
    {
        name: "JEH_DSTAR",
        role: "Brand Designer",
        team: "design",
        image: JessicaPuleo,
        link: "https://www.behance.net/jessicalucepuleo"
    },
    {
        name: "Marco3",
        role: "Brand Designer",
        team: "design",
        image: MarcoMendola,
        link: "https://www.behance.net/marcomendola"
    },
    {
        name: "Elisa",
        role: "Art Dir.",
        team: "design",
        image: ElisaGuareschi,
        link: "https://www.behance.net/mariaeguaresc1"
    },
    {
        name: "Paolo",
        role: "Animator",
        team: "design",
        image: PaoloLuca,
        link: "https://paololuca.it/"
    },
    {
        name: "Rodolfo",
        role: "Lore Writer",
        team: "design",
        image: Rodolfo,
        link: "https://www.behance.net/RodolfoCroce"
    },
    {
        name: "Anis",
        role: "Lead Dev.",
        team: "dev",
        image: Anis,
        link: "https://github.com/anisdzdev"
    },
    {
        name: "Saoud",
        role: "Developer",
        team: "dev",
        image: Saoud,
        link: "https://github.com/saoudmessaoudi"
    },
    {
        name: "Korode",
        role: "Developer",
        team: "dev",
        image: Karode,
        link: "https://github.com/Korodee"
    },
    {
        name: "MARTINI",
        role: "Marketing Lead",
        team: "marketing",
        image: Martini,
        link: "https://twitter.com/MrMartiniNFT"
    },
    {
        name: "IvkoT",
        role: "Marketing Lead",
        team: "marketing",
        image: IvkoT,
        link: "https://twitter.com/IvkoT77"
    },
    {
        name: "Paulo",
        role: "Social Media",
        team: "marketing",
        image: Paulo,
        link: "https://twitter.com/TycoonPal"
    },
    {
        name: "Barry",
        role: "Merchandising",
        team: "merch",
        image: Barry,
        link: "https://www.linkedin.com/in/barrynilson/"
    },
    {
        name: "GR1M",
        role: "Community Mgr.",
        team: "community",
        image: Grim,
        link: "https://linktr.ee/GR1M"
    },
    {
        name: "SappySteve",
        role: "Community Mgr.",
        team: "community",
        image: SappySteve,
        link: "https://twitter.com/steeeve_hh"
    }
]


class TeamSlider1 extends Component {
    render() {
        return (
            <div data-aos="zoom-in" data-aos-duration="800">
                <div className="inline-block team-element">
                    <div className="flex flex-col text-center sm:mr-8 w-fit">
                        <div className="inline-block relative">
                            {/*<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-28 w-28 -z-10">*/}
                            {/*    <img src={teamVector} alt="" />*/}
                            {/*</div>*/}
                            <div onClick={()=> window.open("https://twitter.com/JMCACrypto", "_blank")} className="h-[102px] w-[102px] rounded-full bg-[#D9D9D9] m-auto cursor-pointer">
                                <img src={James} className="rounded-full"/>
                            </div>
                        </div>
                        <span className="block mt-4 font-bold uppercase">James</span>
                        <span className="block mt-2 font-extralight">Business Strategy</span>
                    </div>
                </div>
                <div className="inline-block team-element">
                    <div className="flex flex-col text-center mx-4 sm:mx-8 w-fit">
                        <div className="inline-block relative">
                            {/*<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-28 w-28 -z-10">*/}
                            {/*    <img src={teamVector} alt="" />*/}
                            {/*</div>*/}
                            <div onClick={()=> window.open("https://twitter.com/NftMacca", "_blank")} className="h-[102px] w-[102px] rounded-full bg-[#D9D9D9] m-auto cursor-pointer">
                                <img src={Macca} className="rounded-full"/>
                            </div>
                        </div>
                        <span className="block mt-4 font-bold uppercase">Macca</span>
                        <span className="block mt-2 font-extralight">Operations</span>
                    </div>
                </div>
            </div>
        );
    }
}

class TeamSlider2 extends Component {
    render() {
        return (
            <div data-aos="zoom-in" data-aos-duration="800">
                <ScrollContainer className="team-container overflow-x-auto whitespace-nowrap">
                    {
                        team.filter(member => member.team === this.props.filter).map((member, key) => {
                            return <div key={key} className="inline-block team-element">
                                <div className={`flex flex-col text-center ${ key===0 ? "mr-4 sm:mr-8" : "mx-4 sm:mx-8" } mb-5 w-fit`}>
                                    <div className="inline-block relative">
                                        <div onClick={()=> window.open(member.link, "_blank")} className="h-[102px] w-[102px] rounded-full bg-[#D9D9D9] m-auto cursor-pointer">
                                            <img src={member.image } className="rounded-full"/>
                                        </div>                            </div>
                                    <span className="block mt-4 font-bold uppercase">{member.name}</span>
                                    <span className="block mt-2 font-extralight">{member.role}</span>
                                </div>
                            </div>
                        })
                    }


                </ScrollContainer>
            </div>

        );
    }
}

const Team = React.forwardRef((props, ref) => {
    return (
        <div className="flex flex-col justify-center py-16 lg:py-0 bg-[#141414] text-white bg-cover bg-center bg-no-repeat relative" style={{ backgroundImage: 'url(' + teamBg + ')' }} ref={ref} {...props}>
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-[rgb(20,20,20,1)] via-transparent to-[rgb(20,20,20,1)]"></div>
            <div className="px-4">
                <div className="w-full lg:w-11/12 ml-auto relative z-10">
                    <div data-aos="fade-bottom" data-aos-duration="800" className="text-[56px] font-bold mb-5">Team</div>
                    <div className="mb-10">
                        <div className="text-2xl font-bold mb-6">FOUNDERS</div>
                        <TeamSlider1 />
                    </div>
                    <div className="mb-10">
                        <div className="text-2xl font-bold mb-6">CREATIVE TEAM</div>
                        <TeamSlider2 filter="design" />
                    </div>
                    <div className="mb-10">
                        <div className="text-2xl font-bold mb-6">DEVELOPMENT TEAM</div>
                        <TeamSlider2 filter="dev" />
                    </div>
                    <div className="mb-10">
                        <div className="text-2xl font-bold mb-6">MARKETING TEAM</div>
                        <TeamSlider2 filter="marketing" />
                    </div>
                    <div className="mb-10">
                        <div className="text-2xl font-bold mb-6">COMMUNITY MANAGEMENT</div>
                        <TeamSlider2 filter="community" />
                    </div>
                    <div className="mb-5">
                        <div className="text-2xl font-bold mb-6">MERCHANDISING</div>
                        <TeamSlider2 filter="merch" />
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Team;