import React from 'react';
import yellowBg from '../../assets/img/yellow-bg-2.webp';

import welcomeText1 from '../../assets/img/welcome-text-1.webp';
import welcomeText2 from '../../assets/img/welcome-text-2.webp';

import welcomeTextCenter0 from '../../assets/img/welcome-right-center.webp';
import welcomeTextCenter1 from '../../assets/img/welcome-left-center.webp';

import welcomeCartoonLg from '../../assets/img/welcome-right.webp';
import Parallax from './Parallax';


const AboutMobile = React.forwardRef((props, ref) => {
    return (
        <div className="relative" {...props} ref={ref}>
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 min-w-[480px] w-full -z-1 translate-y-4 lg:translate-y-[15vmin]">
                <img src={yellowBg} alt="" className="w-full" />
            </div>
            <div className="lg:h-screen -mt-12 md:-mt-16 lg:-mt-24 relative z-20">
                <div className="invisible md:visible absolute top-0 right-0">
                    <div className="translate-x-1/4 relative -z-10">
                        <div data-aos="zoom-out" data-aos-duration="800">
                            <img src={welcomeTextCenter0} alt="" className='w-[20vw] sm:w-full' />
                        </div>
                    </div>
                </div>
                <div className="invisible md:visible absolute top-1/2 !-translate-y-1/2 z-[-2] left-0">
                    <div className="-z-10">
                        <div data-aos="zoom-out" data-aos-duration="800" data-aos-delay="600">
                            <img src={welcomeTextCenter1} alt="" className='w-[20vw] animate-slowBounce duration-300' />
                        </div>
                    </div>
                </div>
                <div className="container px-4 lg:px-[10vw] pt-5">
                    <div className="flex justify-center" data-aos="fade-up" data-aos-duration="800">
                        <img src={welcomeText1} alt="" />
                    </div>
                    <div className="flex justify-center" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                        <img src={welcomeText2} alt="" />
                    </div>
                </div>
                <div className="container px-4 lg:px-[15vw]">
                    <div className="w-full 2xl:w-7/12 mx-auto mt-12 px-4">
                        <div className="text-center text-[#E64083] text-sm sm:text-lg md:text-xl lg:text-[max(24px,_1.1vw)] mb-4" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                            The first <b>interdimensional digital collectibles</b> coming from the parallel world of <b>Aeterna.</b>
                        </div>
                        <div className="text-center text-[#E64083] text-sm sm:text-lg md:text-xl lg:text-[max(24px,_1.1vw)] mb-24" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                            Become an Aeterna Landlord and take part in the greatest multiverse adventure ever.
                        </div>
                    </div>
                </div>
                <div data-aos="fade-down" data-aos-duration="800" className="invisible md:visible absolute right-0 top-1/2 !-translate-y-1/2 z-[-2]">
                    <img src={welcomeCartoonLg} alt="" className="w-[20vw] animate-slowBounce-2" />
                </div>
                <Parallax />
            </div>
        </div>
    )
});

export default AboutMobile