import React, { Component } from 'react';
import chapterImage1 from '../../assets/img/Chapter 1.webp';
import chapterImage2 from '../../assets/img/Chapter 2.webp';
import chapterImage3 from '../../assets/img/Chapter 3.webp';

import chapterArrow from '../../assets/img/chapter-arrow.svg';

import Slider from "react-slick";
import {debounce} from "lodash";
import {useMediaQuery} from "react-responsive";


class SimpleSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            elementHeight: 0,
            isLastSlide: false,
            activeSlide: 0
        };
        this.sliderContainerRef = React.createRef();
        this.leftArrowRef = React.createRef();
        this.rightArrowRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            const listHeight = this.slider2.innerSlider.list.clientHeight;
            const elem1Height = this.slider2.innerSlider.list.children[0].children[0].children[0].children[0].clientHeight;
            const elem2Height = this.slider2.innerSlider.list.children[0].children[1].children[0].children[0].clientHeight;
            const elem3Height = this.slider2.innerSlider.list.children[0].children[2].children[0].children[0].clientHeight;

            const itemHeight = Math.max(listHeight, elem1Height, elem2Height, elem3Height);

            this.setState({
                nav1: this.slider1,
                nav2: this.slider2,
                elementHeight: itemHeight
            });
            this.sliderContainerRef.current.querySelector(".slick-list").style.height = `${itemHeight}px`;
            const items = this.sliderContainerRef.current.querySelectorAll(".slick-slide");
            items.forEach(item => item.style.height = `${itemHeight}px`);
            this.placeRightArrow();
        }, 500);

        window.addEventListener('resize', debounce(() => {
            const listHeight = this.slider2.innerSlider.list.clientHeight;
            const elem1Height = this.slider2.innerSlider.list.children[0].children[0].children[0].children[0].clientHeight;
            const elem2Height = this.slider2.innerSlider.list.children[0].children[1].children[0].children[0].clientHeight;
            const elem3Height = this.slider2.innerSlider.list.children[0].children[2].children[0].children[0].clientHeight;

            const itemHeight = Math.max(listHeight, elem1Height, elem2Height, elem3Height);
            this.setState({elementHeight: itemHeight});
            this.placeRightArrow();
        }, 100));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.elementHeight !== this.state.elementHeight) {
            const itemHeight = this.state.elementHeight;

            this.sliderContainerRef.current.querySelector(".slick-list").style.height = `${itemHeight}px`;
            const items = this.sliderContainerRef.current.querySelectorAll(".slick-slide");
            items.forEach(item => item.style.height = `${itemHeight}px`);
        }
    }

    nextSlide = () => {
        this.slider2.slickNext()
    }

    prevSlide = () => {
        this.slider2.slickPrev()
    }

    placeRightArrow = () => {
        const title = this.sliderContainerRef.current.querySelector(".slick-active").querySelector(".chapter-title");

        this.rightArrowRef.current.style.top = `${title.clientHeight/2 + 60}px`;
        this.leftArrowRef.current.style.top = `${title.clientHeight/2 + 60}px`;

        if (this.props.isMobile) {
            this.leftArrowRef.current.style.left = `${title.clientWidth + 70}px`;
            this.rightArrowRef.current.style.left = `${title.clientWidth + 70}px`;
            return;
        }

        this.rightArrowRef.current.style.left = `${title.clientWidth + 20}px`;
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            slideToShow: false,
            vertical: true,
            beforeChange: (oldIndex, newIndex) => {
                this.placeRightArrow();
                this.setState({activeSlide: newIndex});
            }
        };
        return (
            <>
                {
                    !this.props.isMobile &&
                    <div className="absolute top-[5vh] overflow-hidden left-0 hidden lg:block h-[85vh] w-[50vw]">{/*w-5/12*/}
                        <Slider
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            fade={true}
                            arrows={false}
                        >
                            <div data-aos="fade-right" data-aos-duration="800">
                                <img src={chapterImage1} className="h-[85vh]" alt="" />
                            </div>
                            <div data-aos="fade-right" data-aos-duration="800">
                                <img src={chapterImage2} className="h-[85vh]" alt="" />
                            </div>
                            <div data-aos="fade-right" data-aos-duration="800">
                                <img src={chapterImage3} className="h-[85vh]" alt="" />
                            </div>
                        </Slider>
                    </div>
                }
                <div className="px-4 md:px-32 lg:px-4 relative z-20">
                    <div className="flex">
                        <div className="2xl:w-6/12"></div>
                        <div className="lg:h-screen w-full lg:w-6/12 2xl:w-6/12 ml-auto pt-20 lg:pb-28" ref={this.sliderContainerRef}>
                            <div data-aos="fade-down" data-aos-duration="400" className="relative text-3xl md:text-4xl lg:text-[3vw] font-bold whitespace-nowrap mb-6">
                                LORE
                                <div ref={this.leftArrowRef} className="z-20 absolute top-[60px] md:top-[75px] -translate-y-1/2 left-0 -translate-x-14 w-10 h-10 flex items-center justify-center cursor-pointer" onClick={this.prevSlide}>
                                    <img src={chapterArrow} alt="" className='chapter-arrow-prev' />
                                </div>
                                <div ref={this.rightArrowRef} className="z-20 absolute top-[60px] md:top-[75px] -translate-y-1/2 left-[355px] w-10 h-10 flex items-center justify-center cursor-pointer" onClick={this.nextSlide}>
                                    <img src={chapterArrow} alt="" className='chapter-arrow-next' />
                                </div>
                            </div>

                            <Slider
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                                {...settings}
                                className="chapter-slider z-[-1]"
                            >
                                <div className="overflow-hidden">
                                    <div className="chapter-title text-4xl md:text-5xl lg:text-[5vw] font-bold whitespace-nowrap mb-6 w-fit">
                                        Chapter 1
                                    </div>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        <b>380 million years ago</b>, Earth experienced the first interdimensional split in history. Following a major sliding-doors event, a parallel world was born. A planet which was very similar, but also completely different, from its human twin. Its name was <b>Aeterna</b>.
                                    </p>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        <b>It all began with a primordial fish making love with an underwater cave</b>. <br />
                                        On this parallel world, our progenitors did not evolve towards hominids, but astonishing combos of humans, building and trees. They eventually became <b>Househaeds</b>, intelligent creatures with a strong bond with nature and a deep sense of community.
                                    </p>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        <b>Their history is a mess</b>. Following the bizarre laws of interdimensional science, what happened in our world happened also on Aeterna, but in a mashed up way. Through bad and good times, Househaeds never waged war among each other. All the disputes are settled by the most prominent members of their society.
                                    </p>
                                </div>
                                <div className="overflow-hidden">
                                    <div className="chapter-title text-4xl md:text-5xl lg:text-[5vw] font-bold whitespace-nowrap mb-6 w-fit">
                                        Chapter 2
                                    </div>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        <b>Interdimensional voyage came at a high price.</b>,
                                        <br/> In the human year 1492 AD, as Earth was experiencing the discovery of America, on Aeterna a Househaed called Moebius found a way to reach parallel worlds. But before Househaeds managed to get full control on the process, many of them, although warned by <b>the Elders</b>, fell trapped in a space-time distortion called <b>The Limbo</b>, a dead-end recess in the structure of the multiverse.                                    </p>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        Since then, nobody has managed to set them free. But there is a hope. And this hope is humanity.
                                    </p>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        <b>Today,</b> <br />
                                        Unreal Aestate, the first interdimensional real estate company in the multiverse, has come to offer a unique chance to buy Househaeds on the parallel world of Aeterna. And to involve humanity in its first interdimensional adventure ever.
                                    </p>

                                </div>
                                <div className="overflow-hidden">
                                    <div className="chapter-title text-4xl md:text-5xl lg:text-[5vw] font-bold whitespace-nowrap mb-6 w-fit">
                                        Chapter 3
                                    </div>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        <b>For humans, this is not only a big opportunity in the artistic digital collectibles market, but also a way to change Aeterna’s fate. </b> <br/>
                                        As Aeterna scientists recently found, humans share the same genetic heritage of Househaeds. This means that together, Househaeds and humans can finally have a chance to release the long-trapped Househaeds from Limbo.
                                    </p>
                                    <p className="text-sm leading-normal lg:text-[1.1vw] mb-4 w-full lg:w-10/12">
                                        The scientific process behind this is very confusing and boring, as it belongs to interdimensional studies. But to make it immediate, funny and rewarding, Unreal Aestate has turned it into <b>The Quaest, a legendary mission every Househaed’s owner is entitled to join.</b>
                                    </p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                {
                    this.props.isMobile &&
                    <div className="bottom-0 left-0 block lg:hidden w-[100vw]">
                        <Slider
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            fade={true}
                            arrows={false}
                        >
                            <div data-aos="fade-right" data-aos-duration="800">
                                <img src={chapterImage1} alt=""/>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="800">
                                <img src={chapterImage2} alt=""/>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="800">
                                <img src={chapterImage3} alt=""/>
                            </div>
                        </Slider>
                    </div>
                }
            </>
        );
    }
}

const Chaptar = React.forwardRef((props, ref) => {
    const { ref1, ref2 } = ref;
    const isSmallMobile = useMediaQuery({ query: '(max-width: 768px)' })
    return (
        <div className="lg:h-screen z-20 relative overflow-x-hidden text-white mt-0 mb-40 lg:my-40 md:my-60 2xl:my-80" ref={ref1} id={props.id}>
            <SimpleSlider
                ref={ref2}
                isMobile={isSmallMobile}
                {...props}
            />
        </div>
    )
})

export default Chaptar