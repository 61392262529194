import React from 'react';
import footerTop from '../assets/img/footer-top.webp';
import footerHand from '../assets/img/footer-hand.webp';

import { FaDiscord, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

import logo from '../assets/img/logo.webp';

const Footer = React.forwardRef((props, ref) => {
    return (
        <div className="mt-10 lg:mt-[120px] bg-[#141414] text-white" ref={ref} {...props}>
            <div className="h-[274px]">
                <img src={footerTop} alt="" className="w-full h-full" />
            </div>
            <div className="container px-4">
                <div className="w-full lg:w-11/12 mx-auto">
                    <div className="flex flex-wrap items-end">
                        <div className="w-full md:w-1/2">
                            <a href="/#">
                                <img src={logo} alt="" />
                            </a>
                            <div className="font-extralight mt-10">
                                {/*<p className="mb-5">*/}
                                {/*    Capitale sociale: € 000.000 <br />*/}
                                {/*    P.IVA 000000000000 <br />*/}
                                {/*    PEC: Æterna@pec.Æterna.com*/}
                                {/*</p>*/}

                                {/*<p className="mb-5">*/}
                                {/*    SEDE LEGALE <br />*/}
                                {/*    Via una capitale - Rescaldina 20027 (MI)*/}
                                {/*</p>*/}

                                {/*<p className="mb-5">*/}
                                {/*    SEDE COMMERCIALE <br />*/}
                                {/*    Via una capitale - Rescaldina 20027 (MI)*/}
                                {/*</p>*/}

                                {/*<p>*/}
                                {/*    SEDE AMMINISTRATIVA <br />*/}
                                {/*    Via una capitale - Rescaldina 20027 (MI) <br />*/}
                                {/*    Codice univoco fatturazione: 000000*/}
                                {/*</p>*/}
                            </div>
                            <div className="flex gap-10 my-10">
                                <a href="https://twitter.com/Househaeds" target="_blank" className="hover:text-[#F42272]">
                                    <FaTwitter className="h-6 w-6" />
                                </a>
                                <a href="https://discord.gg/hellonaebors" target="_blank" className="hover:text-[#F42272]">                                    <FaDiscord className="h-6 w-6" />
                                </a>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <div className="flex justify-center">
                                <img src={footerHand} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-11/12 mx-auto">
                    <div className="border-t border-[#767676] py-4">
                        <div className="flex gap-6 lg:gap-0 flex-wrap">
                            <div className="w-full lg:w-7/12">
                                <div className="flex flex-wrap gap-x-20 gap-y-4">
                                    <a href="/#" onClick={(e) => e.preventDefault()} className="hover:text-[#F42272] cursor-not-allowed">Privacy Policy</a>
                                    <a href="/#" onClick={(e) => e.preventDefault()} className="hover:text-[#F42272] cursor-not-allowed">Terms of Use</a>
                                </div>
                            </div>
                            <div className="w-full lg:w-5/12">
                                <div className="text-left lg:text-right">
                                    ©Aeterna Labs, Inc. All right reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Footer