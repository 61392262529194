import {FaDiscord, FaTwitter} from "react-icons/fa";
import {ReactComponent as FaOpenSea} from "../assets/img/opensea.svg";


const TopIcons = () => {
    return(
        <div className={`absolute top-[5%] right-0 w-[375px] h-[90px] items-center justify-between z-50`}>
            <ul className={`relative top-0 flex flex-wrap flex-row justify-end gap-5 absolute left-0 bottom-full w-full h-auto px-5 text-right md:text-left`}>
                <li>
                    <a href="https://twitter.com/Househaeds" target="_blank">
                        <FaTwitter className="h-8 w-8" color="white" />
                    </a>
                </li>
                <li>
                    <a href="https://discord.gg/hellonaebors" target="_blank">
                        <FaDiscord className="h-8 w-8" color="white"  />
                    </a>
                </li>
                <li className="h-8 w-8">
                    <a href="https://opensea.io/collection/househaeds" target="_blank">
                        <FaOpenSea color="white" />
                    </a>
                </li>
            </ul>
        </div>
    );

}

export default TopIcons;