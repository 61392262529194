import React, { useState } from 'react';
import logo from '../assets/img/logo-nav.webp';
import logoHH from '../assets/img/logo-hh.webp';
import {ReactComponent as FaOpenSea} from '../assets/img/opensea.svg';

import { GoChevronUp } from 'react-icons/go';

import OutsideClickHandler from 'react-outside-click-handler';
import {FaDiscord, FaTwitter} from "react-icons/fa";

const Navigation = ({ active, scrollTo, isAtBottom }) => {

    const [showNav, setShowNav] = useState(false);

    const openPage = (url)=> {
        window.open(url, '_blank').focus();
    }

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                setShowNav(false)
            }}
        >
            <div className={`fixed bottom-0 left-0 w-full flex gap-10 items-center justify-between z-50 pl-5 pr-5 lg:pr-20 min-h-[90px] font-bold ${active > 2 ? `bg-[#fff] text-black hover:heir-a:text-[#E64083] active-white` : 'bg-[#E64083] text-white hover:heir-a:text-[#EFD95B] active-red'} ${active > 8 || isAtBottom ? 'hidden' : ''}`}>
                {/* Logo */}
                <a href="/#" onClick={(e) => { e.preventDefault(); scrollTo(0) }}>
                    {
                        active > 2 ?
                            <img className="w-1/2" src={logoHH} alt="" /> : <img className="w-1/2" src={logo} alt="" />
                    }
                </a>

                <div className="md:hidden cursor-pointer" onClick={() => setShowNav(!showNav)}><GoChevronUp /></div>

                <ul className={`flex flex-wrap flex-col md:flex-row uppercase justify-end gap-3 lg:gap-5 py-7 absolute md:relative left-0 bottom-full w-full h-auto px-5 text-right md:text-left ${active > 2 ? 'bg-white' : 'md:from-transparent md:to-transparent bg-gradient-to-t from-[#E64083] to-[#fff]'} ${showNav ? '' : 'hidden md:flex'}`}>
                    <li onClick={() => setShowNav(false)}><a href="#home" className={active < 3 ? "active" : ""}>Unreal AEstate</a></li>
                    <li onClick={() => setShowNav(false)}><a href="#chapter" className={active === 3 ? "active" : ""}>Lore</a></li>
                    {/*<li onClick={() => setShowNav(false)}><a href="#shop" className={active === 4 ? "active" : ""}>Merch</a></li>*/}
                    <li onClick={() => setShowNav(false)}><a href="#details" className={active === 5 ? "active" : ""}>Dnae</a></li>
                    <li onClick={() => setShowNav(false)}><a href="#game" className={active === 6 ? "active" : ""}>The Quaest</a></li>
                    {/*<li onClick={() => setShowNav(false)}><a href="#roadmap" className={active === 7 ? "active" : ""}>Roadmap</a></li>*/}
                    <li onClick={() => setShowNav(false)}><a href="#team" className={active === 8 ? "active" : ""}>Team</a></li>
                    <li style={(window.innerWidth > 768) ? {borderLeft: (active > 2) ? "2px solid black":"2px solid white", paddingLeft:"20px"}: {}}>
                        <a href="https://twitter.com/Househaeds" target="_blank">
                            <FaTwitter className="h-6 w-6" />
                        </a>
                    </li>
                    <li>
                        <a href="https://discord.gg/hellonaebors" target="_blank">
                            <FaDiscord className="h-6 w-6" />
                        </a>
                    </li>
                    <li className="h-6 w-6">
                        <a href="https://opensea.io/collection/househaeds" target="_blank">
                            <FaOpenSea/>
                        </a>
                    </li>
                </ul>
            </div>

            <div className={`fixed pb-[90px] top-0 left-0 w-16 z-40 hidden items-center justify-center h-full ${active > 8 ? 'hidden' : 'md:flex'}`}>
                <ul className='child:h-10 child:w-0.5 child:bg-white child:transition child:duration-300 flex flex-col items-center gap-6'>
                    <li onClick={() => scrollTo(0)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 0 ? "h-20 bg-[#E64083]" : ""}`}></li>
                    <li onClick={() => scrollTo(1)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 1 || active === 2 ? "h-20 bg-[#E64083]" : ""}`}></li>
                    <li onClick={() => scrollTo(3)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 3 ? "h-20 bg-[#E64083]" : ""}`}></li>
                    {/*<li onClick={() => scrollTo(4)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 4 ? "h-20 bg-[#E64083]" : ""}`}></li>*/}
                    <li onClick={() => scrollTo(5)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 5 ? "h-20 bg-[#E64083]" : ""}`}></li>
                    <li onClick={() => scrollTo(6)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 6 ? "h-20 bg-[#E64083]" : ""}`}></li>
                    <li onClick={() => scrollTo(8)} className={`cursor-pointer rounded-2xl hover:w-[300%] ${active === 8 ? "h-20 bg-[#E64083]" : ""}`}></li>
                </ul>
            </div>

        </OutsideClickHandler>
    )
}

export default Navigation;