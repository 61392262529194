import React, { useState } from 'react';
import carouselImagPng from '../../assets/img/Reveal.webp';
import carouselImagHover from '../../assets/img/details-image-hover.webp';


const Details = React.forwardRef((props, ref) => {

    const [show, setShow] = useState("1");

    return (
        <div className="lg:h-screen my-20 bg-[#141414] text-white z-40" ref={ref} {...props}>
            <div className="w-full lg:w-11/12 ml-auto z-40">
                <div className="flex flex-wrap items-center">
                    <div className="w-full lg:w-5/12 lg:pr-20 px-4">
                        <div data-aos="fade-top" data-aos-duration="800">
                            <h2 data-aos="fade-top" data-aos-duration="800" className="font-bold text-4xl md:text-[4vw] leading-snug mb-4">
                                DNAE: Details and Rarities
                            </h2>
                            <p className="font-extralight text-sm mb-5 leading-normal lg:text-[1.1vw]">
                                Hand-drawn art pieces by David Sossella, HouseHaeds are programmatically generated from 7 different types of buildings (Houses), including color palette as well as structural variations within each building style, for a total of 19 different base characters. On top of that, there are over 220 unique traits distributed on 9 separate layers for a total amount of potential combinations in the order of hundreds of billions. Literally.
                            </p>
                            <p className="font-extralight text-sm mb-5 leading-normal lg:text-[1.1vw]">
                                The result is a very diverse collection with so many levels of collectability, where each NFT not only has its own personality, but it also truly looks and feels like a one of a kind.
                            </p>
                            <p className="font-extralight text-sm leading-normal lg:text-[1.1vw]">
                                The rarity of their features reflects the seven steps of their society: Common|Uncommon|Rare|Epic|Legendary|Mythic|1:1.
                                Some of the rarest Househaeds are still trapped in Limbo, making them a real endangered species.       </p>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="800" className="w-full relative lg:w-7/12 self-start mt-10 lg:mt-0 lg:h-[calc(100vh_-_90px)]">
                        <div className="bg-gradient-to-tr from-[rgba(244,34,114,0.8)] via-[rgba(244,34,114,0)] to-[rgba(244,34,114,0)] flex flex-wrap lg:flex-nowrap justify-between items-center lg:h-[calc(100vh_-_90px)]">
                            <div className="w-full relative h-[540px] lg:h-full order-2 lg:order-1">
                                <div className={`w-72 sm:w-80 mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ${show === "1" ? " block" : "hidden"}`}>
                                    <div className="relative">
                                        <img src={carouselImagPng} alt="" />
                                        <div className="absolute bottom-0 left-0 h-auto w-full bg-gradient-to-tr from-[rgba(244,34,114,0.8)] via-[rgba(244,34,114,0)] to-[rgba(244,34,114,0)] p-8">
                                            <h4 className="text-[26px]">
                                                *** Hand: <br /> 12%
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="px-6">
                                        <div className="py-3 border-b border-white">
                                            Other in this NFT
                                        </div>
                                        <div className="flex flex-wrap pt-6 gap-y-4">
                                            <div className="w-1/2">
                                                <div className="font-semibold">Body:</div>
                                                <div>9%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Building:</div>
                                                <div>36%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Eyes:</div>
                                                <div>58%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-72 sm:w-80 mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ${show === "2" ? " block" : "hidden"}`}>
                                    <div className="relative">
                                        <img src={carouselImagPng} alt="" />
                                        <div className="absolute bottom-0 left-0 h-auto w-full bg-gradient-to-tr from-[rgba(244,34,114,0.8)] via-[rgba(244,34,114,0)] to-[rgba(244,34,114,0)] p-8">
                                            <h4 className="text-[26px]">
                                                *** Hand: <br /> 13%
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="px-6">
                                        <div className="py-3 border-b border-white">
                                            Other in this NFT
                                        </div>
                                        <div className="flex flex-wrap pt-6 gap-y-4">
                                            <div className="w-1/2">
                                                <div className="font-semibold">Body:</div>
                                                <div>9%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Building:</div>
                                                <div>36%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Eyes:</div>
                                                <div>58%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-72 sm:w-80 mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ${show === "3" ? " block" : "hidden"}`}>
                                    <div className="relative">
                                        <img src={carouselImagPng} alt="" />
                                        <div className="absolute bottom-0 left-0 h-auto w-full bg-gradient-to-tr from-[rgba(244,34,114,0.8)] via-[rgba(244,34,114,0)] to-[rgba(244,34,114,0)] p-8">
                                            <h4 className="text-[26px]">
                                                *** Hand: <br /> 14%
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="px-6">
                                        <div className="py-3 border-b border-white">
                                            Other in this NFT
                                        </div>
                                        <div className="flex flex-wrap pt-6 gap-y-4">
                                            <div className="w-1/2">
                                                <div className="font-semibold">Body:</div>
                                                <div>9%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Building:</div>
                                                <div>36%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Eyes:</div>
                                                <div>58%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-72 sm:w-80 mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ${show === "4" ? " block" : "hidden"}`}>
                                    <div className="relative">
                                        <img src={carouselImagPng} alt="" />
                                        <div className="absolute bottom-0 left-0 h-auto w-full bg-gradient-to-tr from-[rgba(244,34,114,0.8)] via-[rgba(244,34,114,0)] to-[rgba(244,34,114,0)] p-8">
                                            <h4 className="text-[26px]">
                                                *** Hand: <br /> 15%
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="px-6">
                                        <div className="py-3 border-b border-white">
                                            Other in this NFT
                                        </div>
                                        <div className="flex flex-wrap pt-6 gap-y-4">
                                            <div className="w-1/2">
                                                <div className="font-semibold">Body:</div>
                                                <div>9%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Building:</div>
                                                <div>36%</div>
                                            </div>
                                            <div className="w-1/2">
                                                <div className="font-semibold">Eyes:</div>
                                                <div>58%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden ainmate-loopThrough-wrapper inline-flex items-end lg:flex-col lg:mr-10 shrink-0 pb-10 lg:h-[calc(100vh_-_90px)] order-1 lg:order-2">
                                <div className="flex lg:flex-col gap-10 pl-10 lg:pt-10 w-40 ainmate-loopThrough">
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("1")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("2")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("3")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("4")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                </div>
                                <div className="flex lg:flex-col gap-10 pl-10 lg:pt-10 w-40 ainmate-loopThrough">
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("1")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("2")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("3")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                    <div>
                                        <img src={carouselImagPng} alt="" onMouseEnter={() => setShow("4")} onMouseLeave={() => setShow("1")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-75 z-10">
                            <div className="flex flex-col items-center justify-center h-full">
                                <div className="text-white text-[5vw] font-light text-center tracking-[0.3em]">COMING</div>
                                <div className="text-white text-[6vw] font-semibold text-center tracking-wide">SOON</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Details