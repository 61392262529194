import React from 'react';
import gameBg from '../../assets/img/game-bg.webp';
import gameLogo from '../../assets/img/game-bg-2.webp';

import arrowLeft from '../../assets/img/game-arrow-left.webp';
import arrowRight from '../../assets/img/game-arrow-right.webp';

import btnBg from '../../assets/img/btn-bg.svg';
import btnBgBack from '../../assets/img/btn-bg-back.svg';

const Game = React.forwardRef((props, ref) => {
    return (
        <div className="flex items-center lg:h-[calc(100vh_-_90px)] mt-20 py-0 relative bg-[#141414] text-white bg-bottom bg-cover bg-no-repeat" style={{ backgroundImage: "url(" + gameBg + ")" }} ref={ref} {...props}>
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-[rgb(20,20,20,.8)] via-transparent to-[rgb(20,20,20,.8)]"></div>
            <div className="container">
                <div className="relative z-10 px-4">
                    <div className="flex flex-wrap lg:h-[calc(100vh_-_90px)] items-center">
                        <div className="w-full lg:w-3/12">
                            <div data-aos="fade-right" data-aos-duration="800" className="xs:mt-20 lg:mt-0 lg:ml-20 relative">
                                <div className="hidden md:block absolute top-full left-0 lg:left-1/2">
                                    <img src={arrowLeft} alt="" />
                                </div>
                                {/*<p className="font-extralight pr-8 hidden lg:block text-[1.1vw]">*/}
                                {/*    Join the interdimensional rescue mission for incommensurable rewards! Your enemy is not a fellow human, but The Limbo. The more we mine into it, the earlier our efforts will be rewarded. It’s a team game!*/}
                                {/*</p>*/}
                                {/*<div className="block lg:hidden">*/}
                                {/*    <div className="mb-5">*/}
                                {/*        Join the interdimensional rescue mission for incommensurable rewards! Your enemy is not a fellow human, but The Limbo. The more we mine into it, the earlier our efforts will be rewarded. It’s a team game!*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12">
                            <div className="flex flex-col items-center justify-center xs:mt-32 lg:mt-0">
                                <div className="mb-10">
                                    <img src={gameLogo} alt="" />
                                </div>

                                <a data-aos="fade-bottom" data-aos-duration="800" href="/#" onClick={(e) => e.preventDefault()} className="relative max-w-full mt-8 inline-flex translate-x-[15px] items-center justify-center w-80 pt-7 pb-10 mb-16 hover:text-[#E64083] heir-['path']:hover:stroke-[#E64083] group animate-heartBeat">
                                    <img src={btnBgBack} alt="" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10 mt-4 -ml-4 group-hover:m-0 transition-all duration-300" />
                                    <img src={btnBg} alt="" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10" />
                                    <span className="flex items-center gap-3">Coming Soon</span>
                                </a>
                            </div>
                        </div>
                        <div className="w-full lg:w-3/12 hidden lg:block">
                            <div data-aos="fade-left" data-aos-duration="800" className="ml-10 xs:mt-28 lg:mt-0 relative">
                                <div className="hidden md:block absolute top-full right-1/2">
                                    <img src={arrowRight} alt="" />
                                </div>
                                {/*<ul className="list-disc list-inside lg:text-[1.1vw]">*/}
                                {/*        <li>Improve the rarity of your HouseHaeds.</li>*/}
                                {/*        <li>Join an interdimensional dynasty.</li>*/}
                                {/*        <li>Pile up bricks while you can. This may or may not be your standard tokens.</li>*/}
                                {/*        <li>Get a unique chance to win the legendary and epic collectibes.</li>*/}
                                {/*        <li>Three is a magic number.</li>*/}
                                {/*    </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Game