import React, {useEffect, useRef, useState} from 'react';

import heroBg from '../../assets/img/hero-background.webp';

import heroTR from '../../assets/img/hero-top-right.webp';
import heroBL from '../../assets/img/hero-bottom-left.webp';

import heroBRT from '../../assets/img/hero-bottom-right-top.webp';
import heroBRB1 from '../../assets/img/hero-bottom-right-bottom.webp';
import heroBRB2 from '../../assets/img/hero-bottom-right-bottom-2.webp';
import heroBLB from '../../assets/img/hero-bottom-left-cozy.webp';


const Hero = React.forwardRef((props, ref) => {
    const [isPlaying, setIsPlaying] = useState(props.isInHeader);
    const [isMuted] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        setIsPlaying(props.isInHeader);
    }, [props.isInHeader]);

    useEffect(() => {
        if (isPlaying) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
        if (isMuted) {
            videoRef.current.muted = true;
        } else {
            videoRef.current.muted = false;
        }
    }, [isPlaying, isMuted]);

    useEffect(() => {
        function handleVisibilityChange() {
            if (document.hidden) {
                setIsPlaying(false);
            } else {
                setIsPlaying(true);
            }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    function handleVideoDoubleClick() {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) {
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
            videoRef.current.msRequestFullscreen();
        }
    }

    return (
        <div className="relative w-full lg:h-screen pb-[90px] bg-cover bg-bottom bg-no-repeat z-20" style={{ backgroundImage: 'url(' + heroBg + ')' }} {...props} ref={ref}>

            <div className="flex h-full items-center justify-center pt-[50px] lg:pt-0">
                <div className="inline-flex relative">
                    <div className="w-full rounded-3xl">
                        <div className="absolute top-3 left-3 w-full h-full rounded-3xl bg-[#E64083] -z-1"/>
                        <video className="z-1 relative rounded-3xl w-[60vw]" ref={videoRef} onDoubleClick={handleVideoDoubleClick} autoPlay loop muted controls controlsList="nodownload">
                            <source src="https://player.vimeo.com/progressive_redirect/playback/835277124/rendition/1080p/file.mp4?loc=external&signature=52cd88ed4d1354a5ffa12154d17e3d584fbcf9cd1c0be0a3ab932c4cb531896d
" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    {/*<img className="w-full" src={heroMain} alt="" />*/}
                    <div className="absolute top-[-2rem] right-[-3rem] lg:top-[-8rem] md:-top-[4rem] sm:-top-[3rem] sm:-right-[72px] md:-right-[40px] lg:-right-10 z-10">
                        <div data-aos="flip-right" data-aos-duration="800">
                            <img src={heroTR} alt="" className="float-right w-5/12 md:w-7/12 lg:w-10/12 xl:w-full" />
                        </div>
                    </div>
                    <div className="absolute -top-[65%] md:-top-[45%] lg:-top-1/4 rotate-[-20deg] -left-[20%] z-10">
                        <div data-aos="zoom-in" data-aos-duration="800" data-aos-delay="200">
                            <img src={heroBL} alt="" className="w-5/12 md:w-7/12 lg:w-10/12 xl:w-full" />
                        </div>
                    </div>
                    <div className="absolute w-[30%] max-w-[200px] -bottom-10 md:-bottom-16 -left-10 md:-left-20 lg:-left-40 z-[-1]">
                        <div data-aos="zoom-out" data-aos-duration="800" data-aos-delay="300">
                            <div className="animate-heartBeat">
                                <img src={heroBRT} alt="" className="lg:w-10/12 xl:w-full" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute top-0 bottom-0 left-0 right-0 -z-10">
                    <div className="absolute bottom-[-5vh] right-[-15vw] -z-20 translate-y-[20%]">
                        <div data-aos="flip-up" data-aos-duration="800" data-aos-delay="400">
                            <img src={heroBRB1} alt="" className="w-[50vw]" />
                        </div>
                    </div>
                    <div className="absolute bottom-[-5vh] left-[-10vw] -z-20 translate-y-[20%]">
                        <div data-aos="flip-up" data-aos-duration="800" data-aos-delay="400">
                            <img src={heroBLB} alt="" className="w-[30vw]" />
                        </div>
                    </div>
                    <div className="absolute bottom-7 lg:bottom-[30vh] right-20 -z-20 translate-x-[60%]">
                        <div data-aos="flip-down" data-aos-duration="800" data-aos-delay="500">
                            <img src={heroBRB2} alt="" className="w-[25vw]" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
});

export default Hero