import {useEffect, useRef, useState} from 'react';

import Hero from './Hero'
import Chaptar from './Chaptar'
import Shop from './Shop'
import Details from './Details'
import Game from './Game'
import Roadmap from './Roadmap'
import Team from './Team'
import Footer from '../../component/Footer'
import Navigation from '../../component/Navigation'

import useScrollSpy from 'react-use-scrollspy';
import ShopMobile from "./ShopMobile";
import {useMediaQuery} from "react-responsive";
import AboutMobile from "./AboutMobile";
import AOS from "aos";
import MintBar from "../../component/MintBar";
import TopIcons from "../../component/TopIcons";


const Home = () => {

    const canScrollRef = useRef(true);
    const activeSectionRef = useRef(0);
    const sliderRef = useRef(null);

    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })

    const [isAtBottom, setIsAtBottom] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
        const windowHeight =
            "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            setIsAtBottom(true);
            console.log("is bottom")
        }
        else setIsAtBottom(false);
    };

    const sectionRefs = [
        useRef(null), // 0
        useRef(null), // 1
        useRef(null), // 2
        useRef(null), // 3
        useRef(null), // 4
        useRef(null), // 5
        useRef(null), // 6
        useRef(null), // 7
        useRef(null), // 8
        useRef(null), // 9
    ];


    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -80,
    });

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        activeSectionRef.current = activeSection;
        canScrollRef.current = activeSectionRef.current !== 3;
    }, [activeSection]);


    const scrollToSection = (section) => {
        const nextSection = sectionRefs[section].current;
        const y = window.scrollY + nextSection.getBoundingClientRect().top;
        window.scrollTo({
            top: y,
            behavior: 'smooth',
        });
        activeSectionRef.current = section;
    }

    return (
        <div className="bg-[#141414]">
            <TopIcons />
            {/*<MintBar isMobile={false}/>*/}
            <Hero id="home" ref={sectionRefs[0]} isInHeader={activeSection === 0} />
            {
                // isMobile ?
                    <AboutMobile id="about" ref={sectionRefs[1]}/>
                    // : <About id="about" ref={{ref1: sectionRefs[1], ref2: sectionRefs[2]}}/>
            }
            <Chaptar id="chapter" ref={{
                ref1: sectionRefs[3],
                ref2: sliderRef,
            }} />
            {/*{*/}
            {/*    isMobile ? <ShopMobile id="shop" ref={sectionRefs[4]} />*/}
            {/*        : <Shop id="shop" ref={sectionRefs[4]} />*/}
            {/*}*/}
            <Details id="details" ref={sectionRefs[5]} />
            <Game id="game" ref={sectionRefs[6]} />
            {/*<Roadmap id="roadmap" ref={sectionRefs[7]} />*/}
            <Team id="team" ref={sectionRefs[8]} />
            <Footer ref={sectionRefs[9]} />
            <Navigation isAtBottom={isAtBottom} active={activeSection} scrollTo={scrollToSection} />
        </div>
    )
}

export default Home