import React from 'react';

import pcFront from '../../assets/img/parallax-circle-front.webp';

import pr1 from '../../assets/img/parallax-rock-1.webp';
import pr2 from '../../assets/img/parallax-rock-2.webp';
import pr3 from '../../assets/img/parallax-rock-3.webp';
import pr4 from '../../assets/img/parallax-rock-4.webp';
import pr5 from '../../assets/img/parallax-rock-5.webp';

const Parallax = () => {
    return (
        <div className="h-40 sm:h-80 relative z-10 translate-y-[-8vmin]">
            <div className="absolute left-1/2 translate-y-0 -translate-x-1/2 pt-20 lg:pt-0">
                <div data-aos="zoom-out" data-aos-duration="800" data-aos-delay="300">
                    <img src={pcFront} alt="" className='scale-75' />
                </div>
            </div>
            <div className="absolute left-[7.23%] top-[5.1%] lg:top-[54.1%]">
                <img src={pr1} alt="" className="w-1/2 sm:w-full animate-slowBounce-3" />
            </div>
            <div className="absolute left-[36.23%] top-[36.6%]">
                <div className="animate-walk">
                    <img src={pr2} alt="" />
                </div>
            </div>
            <div className="absolute left-[52.23%] -bottom-[20vmin]">
                <img src={pr3} alt="" className="w-1/2 sm:w-full animate-slowBounce-4" />
            </div>
            <div className="absolute left-[80.23%] top-[20%]">
                <img src={pr4} alt="" className="w-1/2 sm:w-full animate-spinSlow" />
            </div>
            <div className="absolute right-0 top-[44.1%] lg:top-[64.1%]">
                <img src={pr5} alt="" className="w-1/2 sm:w-full animate-sideWalk" />
            </div>
        </div>
    )
}

export default Parallax